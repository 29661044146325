@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    li {
        @apply px-4;
    }
}

/* src/index.css */
@font-face {
    font-family: 'OutlineFont';
    src: url('./assets/Fonts/HussarEkologiczne1.otf') format('truetype');
}

@font-face {
    font-family: 'OutlineFontFilled';
    src: url('./assets/Fonts/HussarEkologiczne2.otf') format('truetype');
}
  

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/Fonts/OpenSans.ttf') format('truetype');
  }

body {
    font-family: 'Montserrat', 'Arial';
    background-color: black;
    font-size: 1.05rem;
    line-height: 1.5rem;
}

html {
    scroll-behavior: smooth;
}


.bg-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    left: 0;
    top: 0;
    position: fixed;
    pointer-events: none;
    background-image: url('./assets/ShootoutPhotos/Shootout2023.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.navbar {
    backdrop-filter: blur(8px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}

.personName {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 700; 
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.5rem;
}

.personTitle {
    font-size: 1.2rem;
    line-height: 1.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: rgb(209 213 219);
}

.personProgram {
    font-size: 1.2rem;
    line-height: 1.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: rgb(154, 159, 163);
}

.countdownTimer span{
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Open Sans';
}

.two-numbers {
    width: 2ch;
}

.carouselCard {
    width: 'full';
    padding-left: 10px;
    padding-right: 10px;
}

.headerImage{
    margin: auto;
    display: block;
}

.shootoutCard{
    border-radius: 5px;
    transition: box-shadow 200ms;   
}

.shootoutCard:hover{
    box-shadow: 5px 5px 5px rgb(45, 45, 45);
    cursor: pointer;
}

.sponsorImg {
    float: left;
    height: 150px;
    width: 230px;
    object-fit: contain;
}

.sponsorImg2 {
    float: left;
    height: 80px;
    width: 250px;
    object-fit: contain;
}

.dot {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ff0000; /* Change this to your desired dot color */
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s;
}

.dot.active {
transform: scale(1.5);
}

/* LOADING SCREEN STYLING */

.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out;
}

.loadingScreen.fade-in {
    opacity: 1;
}

.loadingLogo {
    width: 300px; /* Adjust as needed */
}

.headerText {
    font-family: 'Mont-HeavyDEMO'
}

/* .carouselArrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.prevArrow {
    left: 0;
    right: auto;
}

.nextArrow {
    right: 0;
    left: auto;
} */

.react-icons {
    width: "50px";
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #909090;
    color: #ffffff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
content: "";
display: inline-block;
position: absolute;
top: 0;
}
.dot-flashing::before {
left: -15px;
width: 10px;
height: 10px;
border-radius: 5px;
background-color: #909090;
color: #ffffff;
animation: dot-flashing 1s infinite alternate;
animation-delay: 0s;
}
.dot-flashing::after {
left: 15px;
width: 10px;
height: 10px;
border-radius: 5px;
background-color: #909090;
color: #ffffff;
animation: dot-flashing 1s infinite alternate;
animation-delay: 1s;
}

@keyframes dot-flashing {
0% {
    background-color: #ffffff;
}
50%, 100% {
    background-color: rgba(255, 255, 255, 0.2);
}
}

.headerOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, #181818 0%, rgba(0, 0, 0, 0.2) 10%);
}

.headerOverlay2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, #181818 0%, rgba(0, 0, 0, 0.2) 0%);
}
  
.line {
    border-left: 3px solid rgb(255, 255, 255);
    height: 100%;
}

.line2 {
    border-left: 5px solid #DC2626;
    height: 35px;
}

.newsletterCard{
    margin-top: 10px;
    border-radius: 20px;
    background: #1e1e1e;
    cursor: pointer;
    transition: box-shadow 200ms;   
}

.newsletterCard:hover{
    box-shadow: 5px 5px 5px rgb(45, 45, 45);
}

/* .historyWrapper{
    overflow-x: hidden;
    position: relative;
}

.historyContainer{
    display: flex;
    width: 300vw;
} */

.scroll-section-outer {
    overflow: hidden;
    height: auto;
}

.scroll-section-inner {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    position: relative;
}

.scroll-section{
    height: 100vh;
    width: 60vw;
    justify-content: center;
    align-items: center;
}

.scroll-section h3 {
    color: white;
}

iframe {
  width: 100%;
  height: 100vh;
}
  

@media (min-width: 768px) {
  iframe {
    width: 85%;
    height: 60vh;
  }
}

@media (min-width: 1024px) {
  iframe {
    width: 59%;
    height: 60vh;
  }
}

ul {
    cursor: default;
}

.CarYear {
    font-family: 'OutlineFont';
}

.carImage:hover {
    /* box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5); */
    cursor: pointer;
  }
  
  

/* CAR PAGES */
.carTitle {
    font-family: 'OutlineFont';
}

.competitionCard {
    border-radius: 5px;
    transition: box-shadow 200ms;   
}

.competitionCard:hover {
    box-shadow: 5px 5px 5px rgb(10, 0, 43);
}

.teamCard {
    transition: box-shadow 200ms;
}

.teamCard:hover {
    box-shadow: 5px 5px 5px #6b6b6b;
    cursor: default;
}

.teamImg {
    margin: auto;
    width: 90%;
}

/* UT24_Sections */

.container {
    padding: 20px;
  }
  
.accordion-section {
    margin-bottom: 20px;
}

.accordion-button {
    width: 100%;
    padding: 15px;
    text-align: left;
    background-color: #f1f1f1;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accordion-button:hover,
.accordion-button:focus {
    background-color: #ddd;
}

.accordion-content {
    padding: 15px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    padding-bottom: 30px;
}

.carousel {
    display: flex;
    overflow-x: auto;
    gap: 10px;
}

.carousel-card {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.carousel-content {
    padding: 10px;
}

.carousel-content h3 {
    margin: 0 0 10px;
}

.carousel-content p {
    margin: 0;
    font-size: 0.9em;
}

.home-carousel-gif {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.flip-card {
    width: 90%;
    height: 400px;
    perspective: 1000px;
    border-radius: 10px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;

  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(-180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
  }
  
  .flip-card-back {
    background-color: #284185;
    transform: rotateY(180deg);
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }